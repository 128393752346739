<template>
  <div>
    <input type="text" class="text-sm border-grey-field border px-3 py-3 outline-none  w-full rounded-lg" placeholder="Empty" v-model="displayValue" @blur="blur()" @focus="focus()" />
  </div>
</template>
<script>
export default {
  name: 'currency-input',
  data: () => ({
    isInputActive: false
  }),
  props: ['value'],
  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          return this.value?.toString() || ''
        } else {
          return this.value !== '' ? 'Rp ' + this.value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') + ',-' : ''
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\\.]/g, ''))
        if (isNaN(newValue)) {
          newValue = ''
        }
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    blur() {
      this.isInputActive = false
    },
    focus() {
      this.isInputActive = true
    }
  }
}
</script>
