var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayValue,
      expression: "displayValue"
    }],
    staticClass: "text-sm border-grey-field border px-3 py-3 outline-none  w-full rounded-lg",
    attrs: {
      "type": "text",
      "placeholder": "Empty"
    },
    domProps: {
      "value": _vm.displayValue
    },
    on: {
      "blur": function blur($event) {
        return _vm.blur();
      },
      "focus": function focus($event) {
        return _vm.focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.displayValue = $event.target.value;
      }
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }